.h3 {
  @include respond-property('font-size', 30px, 25px, 20px, 20px, 20px, 16px);
  color: #17556A;
  font-weight: 800;
}


.nowrap-style {
  white-space: nowrap !important;
}

.links-column {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  @include respond-down(L) {
    flex: 0 0 17%;
    margin-right: 20px;
    padding-top: 5px;
  }
  @include respond-down(M) {
    flex: 0 0 20%;
    margin-right: 0;
  }
  @include respond-down(S) {
    flex: 0 0 100%;
    margin-right: 0;
    border-top: 1px solid rgb(210, 210, 210);
    border-bottom: 1px solid rgb(210, 210, 210);
    padding: 20px 0 20px 0;
    margin-top: -1px;
  }

  .title {
    @include respond-property('font-size', 25px, 18px, 16px, 16px, 20px, 18px);
    color: #17556A;
    font-weight: 700;
    margin-bottom: 1.2em;
    display: block;
    transition: margin-bottom 0.35s ease;

    @include respond-down(S) {
      margin-bottom: 0;
    }

    svg {
      float: right;
      transform: rotate(90deg);
      transition: transform 0.35s ease;

      path {
        fill: #17556A;
        stroke: #17556A;
      }

      @include respond-up(M) {
        display: none;
      }
    }
  }

  &.full {
    flex: 0 0 100%;
    margin-right: 0;
    border-top: 1px solid rgb(210, 210, 210);
    border-bottom: 1px solid rgb(210, 210, 210);
    padding: 20px 0 20px 0;
    margin-bottom: -1px;
    margin-top: 0;
    
    .title {
      @include respond-down(M) {
        margin-bottom: 0;
      }

      svg {
        display: inline-block;
      }
    }

    .link-items {
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: max-height 0.25s ease, opacity 0.45s ease;
    }
  }

  .link-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include respond-down(S) {
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: max-height 0.25s ease, opacity 0.45s ease;
    }

    .item {
      @include respond-property('font-size', 18px, 14px, 12px, 12px, 14px, 14px);
      color: #909090;
      margin-bottom: 0.5em;
      padding: 3px;
      margin-left: -3px;
      display: inline-block;

      @include respond-up(L) {
        &:hover, &.hover {
          color: #3CE5CE;
        }
      }
    }
  }

  &.opened {
    .title {
      margin-bottom: 1.2em;

      svg {
        transform: rotate(-90deg);
      }
    }
    .link-items {
      max-height: 86px;
      opacity: 1;
    }
  }
}

.link-action {
  @include respond-property('font-size', 16px, 14px, 12px);
  color: #B2B2B2;
  font-weight: 700;
  text-decoration: underline;
  position: relative;
  transition: color 0.2s ease;
  letter-spacing: 0.018em;
  cursor: pointer;
  user-select: none;

  svg {
    path {
      transition: fill 0.2s ease;
    }
  }

  &:hover {
    color: #3CE5CE;

    svg {
      path {
        fill: #3CE5CE
      }
    }
  }
}

.arrow-button {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  background: #6FECDB;
  transition: background-color 0.25s ease, transform 0.2s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  user-select: none;

  svg {
    transition: transform 0.2s ease;

    path {
      stroke: #FFFFFF;
    }
  }

  &:after {
    content: '';
    border-radius: 64px;
    border: 4px solid #fff;
    position: absolute;
    left: 4px;
    top: 4px;
    right: 4px;
    bottom: 4px;
  }

  @include respond-down(XL){
    width: 50px;
    height: 50px;

    &:after {
      border: 2px solid #fff;
      left: 3px;
      top: 3px;
      right: 3px;
      bottom: 3px;
    }

    svg path {
      stroke-width: 3
    }
  }

  @include respond-down(L){
    width: 44px;
    height: 44px;

    &:after {
      border: 3px solid #fff;
    }

    svg {
      width: 8px;
    }
  }

  @include respond-up(L){
    &:hover {
      background: #16E3C8;
    }
  }

  &.color-red {
    width: 50px;
    height: 50px;
    background: #FC6D94;

    &:after {
      left: -8px;
      top: -8px;
      right: -8px;
      bottom: -8px;
    }

    @include respond-down(XL){
      width: 36px;
      height: 36px;

      &:after {
        border: 3px solid #FC6D94;
        left: -6px;
        top: -6px;
        right: -6px;
        bottom: -6px;
      }
    }

    @include respond-down(L){
      width: 30px;
      height: 30px;
    }

    @include respond-up(L){
      opacity: 0.7;
      transition: opacity 0.25s ease, transform 0.25s ease;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.to-left {
    &.animation-go {
      transform: translateX(-10px);
    }

    svg {
      margin-right: 1px;
      transform: rotate(180deg);
    }

    @include respond-up(L){
      &:hover {
        svg {
          transform: rotate(180deg) translateX(3px);
        }
      }
    }
  }

  &.to-right {
    &.animation-go {
      transform: translateX(10px);
    }

    svg {
      margin-left: 1px;
    }

    @include respond-up(L){
      &:hover {
        svg {
          transform: translateX(3px);
        }
      }
    }
  }

}

.block-header {
  @include respond-property('margin-bottom', 62px, 62px, 50px, 50px, 38px, 28px);

  .block-title {
    @include respond-property('font-size', 50px, 40px, 35px, 35px, 30px, 25px);
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 1.25em;
    color: #17556A;
    font-weight: 800;
    padding-left: 15px;
    padding-right: 15px;
  }

  .block-subtitle {
    @include respond-property('font-size', 25px, 20px, 18px, 18px, 16px, 14px);
    color: #909090;
    text-align: center;
    line-height: 1.35em;
    letter-spacing: 0.004em;
    font-weight: 400;
  }
}

.h1.page-title {
  @include respond-property('font-size', 30px, 30px, 25px, 20px, 20px, 20px);
  @include respond-property('margin-bottom', 54px, 40px, 34px, 30px, 22px, 16px);
  color: #17556A;
}

.location-link {
  color: #909090;
  transition: color 0.2s ease;
  font-size: 14px;
  display: inline-flex;
  align-items: center;

  @include respond-down(XL){
    font-size: 12px;
  }

  svg {
    margin-top: -2px;
    margin-right: 5px;
    path {
      transition: fill 0.2s ease;
    }
  }

  @include respond-up(L) {
    &:hover {
      color: #3CE5CE;

      svg path {
        fill: #3CE5CE;
      }
    }
  }
}


.breadcrumbs {
  //margin-bottom: 9px;
  //margin-top: -60px;
  //margin-bottom: 16px;
  font-size: 12px;
  @include respond-property('margin-bottom', 36px, 24px, 22px);
  @include respond-property('font-size', 16px, 14px);
  font-weight: 200;
  display: flex;
  align-items: center;
  background-color: transparent;

  .breadcrumb {
    color: #6FECDB;
    background-color: transparent;
    display: block;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    border-radius: 0.25rem;

    &.link {
      transition: color 0.25s ease;
      color: #909090;
      cursor: pointer;

      &:hover {
        color: #6FECDB;
      }

      &:after {
        content: '>';
        color: #909090;
        display: inline-block;
        margin: 3px 12px 3px 12px;
      }
    }
  }
}

.gradient, .gradient:after {
  content: '';
  position: absolute;
  z-index: 20;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(to bottom,
          rgba(252, 109, 148, 0) 0%,
          rgba(252, 109, 148, 0.15) 30%,
          rgba(252, 109, 148, 0.75) 70%,
          rgba(252, 109, 148, 0.90) 100%);
  opacity: 0.75;
  transition: opacity 0.25s ease;
}

.checkbox {
  @include respond-property('width',  20px, 18px, 16px, 24px, 18px, 18px);
  @include respond-property('height', 20px, 18px, 16px, 24px, 18px, 18px);
  border: 2px solid #E2E2E2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #FFFFFF;
  transition: background 0.2s ease, border 0.2s ease;
  flex: 0 0 auto;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 70%;
    height: 70%;
    left: 20%;
    top: 15%;
    background: no-repeat center center url('/static/images/svg/check-mark.svg');
    background-size: contain;
  }

  &:hover, &.hover {
    border-color: #16E3C8;
  }

  &.red {
    &:hover, &.hover {
      border-color: #FC6D94;
    }
  }
}

.active .checkbox.red,
.checkbox.red.active,
.checkbox.red:active {
  background: #FC6D94;
  border-color: #FC6D94;
}

.active .checkbox,
.checkbox.active,
.checkbox:active {
  background: #16E3C8;
  border-color: #16E3C8;
}

.form-control {
  @include respond-property('height', 46px, 32px, 32px, 45px, 30px, 30px);
  @include respond-property('font-size', 18px, 16px, 14px, 18px, 16px, 14px);
  padding-left: 1em;
  padding-right: 1em;
  border: 1px solid rgba(226, 226, 226, 0.8);
  background: #FFFFFF;
  border-radius: 4px;
  align-items: center;
  color: #373737;
  width: 100%;

  @include respond-down(S){
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #E2E2E2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6FECDB;
  }
}

[data-toggle-parent], [data-toggle] {
  cursor: pointer;
}



.titled-item {
  position: relative;

  .head {
    @include respond-property('padding-left', 34px, 26px, 24px, 24px);
    @include respond-property('margin-bottom', 50px, 38px, 38px, 26px, 26px, 20px);

    .subtitle {
      @include respond-property('font-size', 25px, 20px, 18px, 18px, 16px, 16px);
      @include respond-property('margin-bottom', 22px, 20px, 15px, 10px, 10px, 6px);
      color: #17556A;
      padding-top: 0.2em;

      @include respond-up(M){
        position: relative;
      }
    }

    .subtitle, .page-title {
      &:before {
        content: '';
        border-radius: 0 6px 6px 0;
        background: #16E3C8;
        position: absolute;
        top: 0;
        @include respond-up(L){
          border-radius: 0 4px 4px 0;
        }
        @include respond-up(M){
          top: 50%;
        }
        @include respond-property('width',       16px,  13px,  12px,  12px,   9px,  9px);
        @include respond-property('height',      40px,  32px,  28px,  28px,  22px, 22px);
        @include respond-property('margin-top', -20px, -16px, -14px, -14px,  -2px, -2px);
        @include respond-property('left',       -34px, -26px, -24px, -24px,   0px,  0px);

      }

      &.green-pimpa:before {
        background: #62E994;
      }

      &.green-x-pimpa:before {
        background: #16E3C8;
      }

      &.yellow-pimpa:before {
        background: #FFEF60;
      }

      &.red-pimpa:before {
        background: #FF5D5D;
      }
    }

    .helper {
      @include respond-property('font-size', 20px, 16px, 16px, 16px, 14px, 14px);
      line-height: 1.35em;
      color: #909090;
    }
  }
}

