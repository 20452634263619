.product-cart {
  position: relative;

  @keyframes zoomer {
    0% { transform: scale(0.9); }
    50% { transform: scale(1.1); }
    100% { transform: scale(0.9); }
  }

  .hello {
    position: absolute;
    z-index: 5;
    left: 20px;
    bottom: 220px;
    transition: transform 0.25s ease, left 0.25s ease;
    transform: rotate(-25deg);

    @include respond-down(XL){
      bottom: 140px;
    }
    @include respond-down(M){
      display: none;
    }

    .eye-1 {
      transform-origin: 30% 10%;
      animation: zoomer 1s ease infinite;
    }
    .eye-2 {
      transform-origin: 20% 10%;
      animation: zoomer 1s ease infinite;
    }
  }

  &.hello-action {
    .hello {
      left: -60px;
      transform: rotate(-45deg);
    }
  }

  .product-frame {

    .sizes-list {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      letter-spacing: 0.018em;
      overflow: hidden;

      height: 0;
      opacity: 0;
      transition: height 0.25s ease, opacity 0.45s ease;

      @include respond-down(M){
        height: auto;
        position: absolute;
        bottom: -50px;
        opacity: 1;
      }
      @include respond-down(M){
        bottom: -45px;
      }
      @include respond-down(XS){
        bottom: -40px;
      }

      .sizes-title {
        color: #B2B2B2;
        font-size: 14px;
        margin-bottom: 0.5em;

        @include respond-down(XL) {
          font-size: 12px;
        }
        @include respond-down(M){
          display: none;
        }
      }

      .sizes-items {
        display: flex;
        margin-bottom: 20px;
        
        //overflow: hidden;
        //white-space: nowrap;
        //max-width: 100%;
    
        @include respond-down(XL) {
          margin-bottom: 12px;
        }
      }
    }

    .tech-list {
      height: 28px;
      display: flex;
      margin-bottom: 2px;
      overflow: visible;
      flex-wrap: wrap;
      margin-right: 60px;
      position: relative;
      z-index: 20;

      @include respond-down(XL){
        margin-right: 50px;
        height: 20px;
      }
      @include respond-down(M){
        margin-right: 46px;
      }
      @include respond-down(S){
        margin-right: 40px;
      }
      @include respond-down(XS){
        margin-right: 36px;
        height: 18px;
      }

      .tech-item {
        @include respond-property('margin-right', 8px, 6px, 6px, 5px, 4px, 2px);
        @include respond-property('margin-bottom', 8px, 6px, 6px, 5px, 4px, 2px);

        img {
          width: auto;
          height: 28px;
          @include respond-down(XL){
            height: 20px;
          }
          @include respond-down(XS){
            height: 18px;
          }
        }
      }
    }

    .product-image {
      @include respond-property('height', 190px, 180px, 160px, 146px, 120px, 120px);
      display: block;
      position: relative;
      z-index: 10;
      margin: 0 -15px -10px -15px;
      background: no-repeat center center;
      background-size: contain;
      //transition: opacity 1.55s ease;
      transition: opacity 0.5s ease;

      opacity: 1;

      &.hide {
        opacity: 0;
      }

      @include respond-down(M){
        margin: 0 -12px -8px -12px;
      }
    }
    
    .attention-marks {
      position: absolute;
      @include respond-property('top',      -28px,-23px,-10px,-13px,-15px,-15px);
      @include respond-property('right',    -18px,-16px,-10px,-16px,-12px,-12px);
      @include respond-property('width',     56px, 56px, 48px, 48px, 38px, 38px);
      display: flex;
    }
    .attention {
      @include respond-property('font-size', 14px, 12px, 10px, 12px,  9px,  9px);
      @include respond-property('width',     56px, 46px, 40px, 40px, 30px, 30px);
      @include respond-property('height',    56px, 46px, 40px, 40px, 30px, 30px);
      padding-top: 0.15em;
      margin-bottom: 8px;
      margin-left: 8px;
      z-index: 30;
      background: #62E994;
      color: #FFFFFF;
      font-weight: 700;
      letter-spacing: 0.004em;
      border-radius: 56px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      @include respond-down(L){
        margin-left: 4px;
      }

      &.new-mark {
        background: #FFF065;
        color: #373737;
      }
      &.sale-mark {
        background: #FF5D5D;
      }
    }

    .heart {
      @include respond-property('width',  36px, 30px, 25px, 30px, 24px, 24px);
      @include respond-property('height', 36px, 30px, 25px, 30px, 24px, 24px);

      position: absolute;
      right: 12px;
      bottom: 12px;
      z-index: 30;
      border-radius: 56px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #EBEBEB;
      cursor: pointer;

      display: none; // Temporary

      svg {
        width: 58%;

        path {
          transition: fill 0.25s ease, stroke 0.25s ease;
          fill: transparent;
          stroke: #B2B2B2;
        }
      }

      @include respond-down(M){
        right: 8px;
        bottom: 8px;
      }

      @include respond-up(L){
        &:hover {
          svg path {
            fill: #FC6D94;
            stroke: #FC6D94;
          }
        }

        &:active {
          background: #FC6D94;

          svg path {
            fill: #FFFFFF;
            stroke: #FFFFFF;
          }
        }
      }

      &.active {
        background: #FC6D94;

        svg path {
          fill: #FFFFFF;
          stroke: #FFFFFF;
        }
      }
    }

    @include respond-up(L){
      &:hover {
        margin-top: -68px;
        box-shadow: 5px 7px 20px rgba(0,0,0,0.17);

        @include respond-down(XL){
          margin-top: -54px;
        }

        .sizes-list {
          opacity: 1;
          height: 68px;

          @include respond-down(XL){
            height: 54px;
          }
        }
      }
    }
  }

  .product-text {
    //padding: 0 35px 0 0;

    @include respond-down(M){
      padding: 0 18px;
    }
    @include respond-down(S){
      padding: 0 10px;
    }

    .gender {
      position: relative;
      min-width: 32px;
      min-height: 32px;
      width: 32px;
      height: 32px;
      float: right;
      //bottom: 70px;
      //@include respond-property('bottom', 70px, 70px, 60px, 50px, 50px, 50px);
      //@include respond-property('right', -20px, -20px, -10px, -10px, 0px, 0px);
      //right: -20px;
      z-index: 20;
    }

    .title {
      @include respond-property('height', 46px, 40px, 36px, 36px, 35px, 35px);
      @include respond-property('font-size', 18px, 16px, 14px, 14px, 14px, 14px);
      line-height: 1.3em;
      overflow: hidden;
      margin-bottom: 0.5em;
      font-weight: 500;
      display: block;
      color: #373737;

      @include respond-down(M){
        font-weight: 500;
      }
    }
  }
}

.product-frame {
  @include respond-property('margin-bottom', 25px, 16px, 10px, 44px, 38px, 38px);
  @include respond-property('padding', 23px, 14px, 12px, 14px, 8px, 8px);
  border-radius: 25px;
  border: 4px solid #FBDEED;
  background: #FFFFFF;
  min-height: 40px;
  position: relative;
  z-index: 20;
  transition: margin-top 0.25s ease, box-shadow 0.35s ease;

  @include respond-down(XL){
    border-radius: 15px;
    border: 3px solid #FBDEED;
  }

  @include respond-down(S){
    border-radius: 10px;
    border: 2px solid #FBDEED;
  }
}

.product-sizes {
  display: flex;
  // overflow: hidden;
  // max-width: 200px;

  .size {
    @include respond-property('font-size', 14px, 12px, 12px, 12px, 10px, 10px);
    @include respond-property('min-width', 30px, 26px, 26px, 26px, 18px, 18px);
    cursor: pointer;
    user-select: none;
    padding: 3px 4px 2px 4px;
    line-height: 1.3em;
    border-radius: 4px;
    border: 2px solid #B2B2B2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #464646;
    margin-right: 7px;
    transition: background 0.15s, border 0.15s, color 0.15s;

    @include respond-down(XL){
      padding: 4px 4px 3px 4px;
      margin-right: 6px;
      line-height: 1em;
    }
    @include respond-down(L){
      margin-right: 5px;
    }
    @include respond-down(S){
      //padding: 3px 3px 2px 3px;
    }
    @include respond-down(XS){
    }

    @include respond-up(L){
      &:hover:not(.disabled) {
        border-color: #FC6D94;
        color: #FC6D94;
      }
    }

    &:active:not(.disabled), &.active {
      border-color: #FC6D94;
      background-color: #FC6D94;
      color: #FFFFFF !important;
    }

    @include respond-down(S) {
      border: 1px solid #B2B2B2;
      margin-right: 3px;
    }

    &.disabled {
      border-color: #D6D6D6;
      color: #D6D6D6;
      cursor: default;
    }
  }
}

.product-price {
  @include respond-property('font-size', 20px, 18px, 16px, 16px, 12px, 10px);
  display: flex;
  font-weight: 700;
  letter-spacing: 0.018em;
  color: #373737;

  .old-price {
    margin-right: 0.4em;
    color: #B2B2B2;
    position: relative;
    white-space: nowrap;

    @include respond-down(M){
      margin-right: 10px;
    }

    &:after {
      height: 1px;
      position: absolute;
      left: -3px;
      right: -3px;
      top: 48%;
      background: #B2B2B2;
      content: '';
    }
  }

  .new-price {
    font-weight: 400;
    color: #FF5D5D;
    white-space: nowrap;
  }
}
