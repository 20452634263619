* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  @include respond-property('font-size', 18px, 16px, 16px, 16px, 12px, 12px);
  position: relative;
  font-family: $font;
  color: #464646;
  letter-spacing: 0.018em;

  &::selection {
  }
}

a {
  text-decoration: none;
  transition: color .25s ease;
}

a:hover {
  text-decoration: none;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

[data-link] {
  cursor: pointer;
}

strong {
  color: #464646;
  font-weight: 700;
  letter-spacing: 0.018em;
  line-height: 1.35em;

  &.thin {
    font-weight: 500;
  }
}

.body {
  position: relative;
  @include respond-down(L){
    overflow-x: hidden;
  }
}

.container {
  width: 1360px;
  margin: auto;
  padding-right: 0;
  padding-left: 0;

  @include respond-down(XL){
    width: 1130px;
  }
  @include respond-down(L){
    width: auto;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.container-full {
  width: auto;
  margin-left: 5%;
  margin-right: 5%;

  &.limited {
    @include respond(XXL){
      max-width: 1360px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include respond-down(M) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @include respond-up(L) {
    margin-left: 1%;
    margin-right: 1%;
  }
  @include respond-up(XL) {
    margin-left: 5%;
    margin-right: 5%;
  }

}

@keyframes scale {
  0% { transform: scale(0.8); }
  50% { transform: scale(1); }
  100% { transform: scale(0.8); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes updown {
  0% { transform: translateY(5px) rotate(-12deg); }
  50% { transform: translateY(-10px) rotate(12deg); }
  100% { transform: translateY(5px) rotate(-12deg); }
}

h1, .h1 {
  font-weight: 700;
  line-height: 30px;
}

label {
  display: block;
  margin-bottom: 0;
}

.width800{
  width: 800px;
}

.public-information-box {
  font-size: 16px;
  line-height: 1.5em;
  text-align: justify;
  h1 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    font-family: PTSansBold, Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
    letter-spacing: 0.01em;
    color: #464646;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  h5 {
    font-size: 24px;
    color: #12BF8C;
    line-height: 25px;
    letter-spacing: .05em;
    font-family: PTSansBold, Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    padding-bottom: 20px;
  }
  p {
    padding-bottom: 20px;
  }
  .payment-page {
    width: 100%;
    padding-bottom: 40px;
  }
}

.p-0 {
  padding: 0;
}

.pb-4 {
  padding-bottom: 20px;
}

.pb-2 {
  padding-bottom: 10px;
}


.hidden {
  display: none !important;
}


.select_shop {
  padding: 20px;
  border: 1px solid #aeaeae;
}

.fixed-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
}

.modal2 {
  margin: auto;
  background: white;
}

.modal_container2 {
    display: block;
    border: none;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

//.modal-content {
//  display: flex;
//  align-items: center;
//  justify-content: center;
//}

.c-pointer {
  cursor: pointer;
}

.cart {
  background-color: #ececec;
  box-shadow: 0 0 8px rgba(64,64,64,0.3);
  box-sizing: border-box;
  height: 100%;
  left: auto;
  min-height: 500px;
  padding: 13px 0 13px 6px;
  position: fixed;
  right: -506px;
  top: 0;
  width: 500px;
  z-index: 5001;
}


// фикс для сафари иначе правый столбец уезжал вниз
.row {
  &:before, &:after {
    display: inline-block;
  }

  &:after {
    display: inline-block;
  }
}

.info-page {
  .width90percent{
    padding-left: 5%;
    padding-right: 5%;
  }
  .main-text {
    h4 {
      padding-bottom: 2em;
      text-align: center;
    }
    p {
      text-align: justify;
      padding-bottom: 1em;
      line-height: 1.5em;
      text-indent: 20px; /* Отступ первой строки в пикселах */
      @include respond-down(M) {
        font-size: 16px;
      }
    }
  }
}


.maxw100 {
  max-width: 100%;
}

.min-width-programs {
  min-width: 200px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.floating-cookies {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 9999;
  bottom: 28px;
  //background: white;
  @include respond(XS){
    display: none;
  }
  @include respond(S){
    display: none;
  }
  @include respond(M){
    display: none;
  }

  .hidden{
    display: none !important;
  }

  .details-privacy {
    display: flex;
    //justify-content: space-between;
    text-align: center;
    margin: auto;
    //max-width: 100%;
    //flex: 0 0 auto;
    //width: 200px;
    font-size: 10px;

    @include respond(S){
      flex: 0 0 50%;
      margin: 0;
      padding-left: 20px;
    }


    .mailto {
      text-decoration: underline;
      font-weight: 700;
      color: #909090;
    }
    .flex-column-footer{
      background: #ccc;
      //text-align: center;
      padding: 15px;
      box-sizing: border-box;
      //width: 50%;
      //white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
    }
  }

}

