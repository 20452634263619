@font-face {
	font-family: "Muller";
	src: url("MullerLight.otf");
	font-style: normal;
	font-weight: 200;
	font-display: swap;
}
@font-face {
	font-family: "Muller";
	src: url("MullerRegular.otf");
	font-style: normal;
	font-weight: 400;
    font-display: swap;
}
@font-face {
	font-family: "Muller";
	src: url("MullerMedium.otf");
	font-style: normal;
	font-weight: 500;
    font-display: swap;
}
@font-face {
	font-family: "Muller";
	src: url("MullerBold.otf");
	font-style: normal;
	font-weight: 700;
    font-display: swap;
}
@font-face {
	font-family: "Muller";
	src: url("MullerExtraBold.otf");
	font-style: normal;
	font-weight: 800;
    font-display: swap;
}
