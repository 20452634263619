.drop-down {
  position: relative;
  user-select: none;

  .selected {
    @include respond-property('height', 40px, 36px, 30px, 40px, 30px, 36px);
    display: flex;
    align-items: center;
    border: 2px solid #6FECDB;
    border-radius: 4px;
    min-width: 230px;
    justify-content: space-between;
    cursor: pointer;
    transition: border 0.2s ease;

    @include respond-down(XL) {
      min-width: 0;
    }

    .name {
      @include respond-property('font-size', 16px, 14px, 14px, 16px, 14px, 14px);
      //padding: 0 12px;
      padding: 0 0.5vw;
      color: #464646;
      letter-spacing: 0.016em;
      white-space: nowrap;
    }

    .decorator {
      @include respond-property('width', 40px, 36px, 30px, 40px, 36px, 30px);
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      background: #6FECDB;
      transition: background 0.2s ease;

      svg {
        transform: rotate(90deg);
        transition: transform 0.35s ease;
      }
    }

    &:hover {
      border-color: #16E3C8;

      .decorator {
        background: #16E3C8;
      }
    }
  }

  .variants {
    @include respond-property('top', 50px, 46px, 40px, 50px, 46px, 40px);
    min-width: 230px;
    position: absolute;
    left: 0;
    background: #FFFFFF;
    box-shadow: 5px 7px 20px rgba(0,0,0,0.12);
    border-radius: 4px;
    cursor: pointer;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    z-index: 60;
    transform: translateY(-10px);
    transition: opacity 0.25s ease, transform 0.25s ease, max-height 0s;
    transition-delay: 0s, 0s, 0.25s;

    .checkbox-wrapper {
      @include respond-property('padding', 22px, 20px, 20px, 22px, 20px, 20px);
      max-height: 290px;
      overflow: auto;
      font-size: 16px;

      @include respond-down(XL){
        font-size: 14px;
      }
      @include respond-down(M){
        font-size: 16px;
      }

      .separated {
        display: flex;

        .item {
          flex: 0 0 50%;
        }
      }

      .items-title {
        letter-spacing: 0.016em;
        font-weight: bold;
        margin-bottom: 1em;
      }

      .items-list {

        .variant {
          display: flex;
          align-items: center;
          padding: 4px 20px 4px 0;
          min-width: 170px;
          white-space: nowrap;

          .checkbox {
            margin-right: 10px;

            @include respond-down(XL){
              margin-right: 8px;
            }
          }
        }
      }
    }

    .append {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-top: 1px solid #E2E2E2;

      @include respond-down(XL){
        height: 56px;
      }

      .button {
        background: #6FECDB;
        height: 40px;
        border-radius: 4px;
        min-width: 180px;
        margin-right: 1em;
        margin-left: 1em;

        @include respond-down(XL){
          height: 30px;
        }

        &:hover {
          background: #16E3C8;
        }
      }
    }

    .radio-wrapper {
      @include respond-property('padding', 14px, 12px, 10px);
      font-size: 16px;

      @include respond-down(XL){
        font-size: 14px;
      }
      @include respond-down(M){
        font-size: 16px;
      }

      .variant {
        position: relative;
        padding: 7px 20px;
        cursor: pointer;
        transition: color 0.2s ease;

        @include respond-down(XL){
          padding: 5px 12px;
        }

        &:after {
          content: '';
          width: 8px;
          height: 8px;
          margin-top: -4px;
          border-radius: 12px;
          position: absolute;
          top: 50%;
          left: 0;
          transition: background-color 0.2s ease;

          @include respond-down(XL){
            width: 6px;
            height: 6px;
            margin-top: -3px;
          }
        }

        &.active {
          color: #16E3C8;

          &:after {
            background: #16E3C8;
          }
        }

        &:hover {
          &:after {
            background: #6FECDB;
          }
        }
      }
    }
  }

  &.opened {
    .selected {
      .decorator {
        svg {
          transform: rotate(-90deg);
        }
      }
    }

    .variants {
      max-height: 999px;
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0s, 0s, 0s;
    }
  }
}