@import "settings";
@import "_common/grid";

@import "_common/reset";

@import "_parts/base";
@import "_parts/typo";
@import "_parts/button";

@import "_parts/mobile-menu";
@import "_parts/product-cart";
@import "_parts/drop-down";

@import "_vendors/owl";
@import "_vendors/balloon";

// Common parts
@import "_box/collections-box";
@import "_box/default-menu-box";
@import "_box/sale-line-box";
@import "_box/footer-box";
@import "_box/popular-box";

[data-debug="debug"]:after {
  position: fixed;
  padding: 5px;
  opacity: 0.5;
  background: #f00;
  color: #fff;
  border-radius: 5px;
  left: 10px;
  bottom: 10px;
  z-index: 99999;
  width: 24px;
  height: 24px;
  content: 'XXL';
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: sans-serif;
  font-weight: bold;
  line-height: 1em;
  font-size: 14px;
  @include respond-down(XL)  { content: 'XL';  }
  @include respond-down(L)  { content: 'L';  }
  @include respond-down(M)  { content: 'M';  }
  @include respond-down(S)  { content: 'S';  }
  @include respond-down(XS) { content: 'XS'; }
}

.page-order {
  /* Important part */
  .upload-1, .upload-2, modalcenter, .loyalty_regular {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-bold {
    font-weight: bold;
  }
  .modal-dialog{
    overflow-y: initial !important;
    height: 95%;
    //text-align:center;
    @include respond-down(L){
      width: 95%;
    }
    @include respond-up(XL){
      width: 90%;
    }
    margin: 10px;
  }
  .modal-body{
    //height: 95vh;
    max-height: 90vh;
    overflow-y: auto;
  }

  .uploader {
    width: 100%;
    max-width: 600px;
  }

  .plupload_filelist_header {
    font-size: 18px;
  }

  .plupload_filelist_header {
    font-size: 18px;
  }

  .plupload_file_name {
    font-size: 18px;
  }

  .plupload_header_text {
    //display: flex;
    //flex-wrap: wrap;
    width: 80%;
  }
  .plupload_wrapper {
    min-width: 350px;
  }
  .ui-button-text {
    font-size: 14px;
  }
  .plupload_buttons {
    width: 100%;
  }
  .plupload_add, .plupload_start {
    width: 50%
  }

  .order-programs{
    text-align: justify;
    font-size: 20px;
    @include respond-down(L){
      line-height: 1.2;
    }
    @include respond-up(XL){
      line-height: 1.5;
    }

  }



  .radio {
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: auto;
  }

  input[type='radio'],
  label {
    cursor: pointer;
  }

  input[type='radio'] {
    position: relative;
    height: 22px;
    width: 22px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 10px;
  }

  input[type='radio']::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: initial;
    border: 2px solid #12BF8C;
    vertical-align: middle;
  }

  input[type='radio']:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #12BF8C;
    transform: translate(-50%, -50%);
    visibility: visible;
    vertical-align: middle;
  }
}



.manager{
  .topmenu{
    font-weight: bold;
    font-size: 18px;
  }
  .navbar-item {
    font-weight: 600
  }

  .navbar-light .navbar-nav .nav-link {
    /*color: rgba(0, 0, 0, 0.87);*/
    /*padding: 6px 16px;*/
    /*min-width: 64px;*/
    /*box-sizing: border-box;*/
    /*transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;*/
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
  }

  li.nav-item.active, li.nav-item:hover, li.nav-item:focus {
    background-color: #5dadd5
  }

  li.nav-item:hover, li.nav-item:focus {
    background-color: #c2f3ff
  }

}

.sizes-bgimage{
  @include respond-down(M)  {
    background-image: url("/static/images/sizes-sm.png");
    width: 100%;
    margin: 0 !important;
    padding-bottom: 40% !important;
    //background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  @include respond-up(L)  {
    background-image: url("/static/images/lineyka-socialsets_MM09-2.png");
    width: 100%;
    margin: 0 !important;
    padding-bottom: 20% !important;
    //background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

}

//.img1 {
//  height: 82px;
//}

.basket {
  z-index: 99999;
  position: fixed;
  top: 50vh;
  right: 0px;
  width: 64px;
  height: 40px;
  background-color: #BBF6EE;
  .basket-image {
    position: relative;
    background: rgba(0,0,0,0) url(/static/images/basket.svg) no-repeat scroll center center/97% 97%;
    height: 32px;
    width: 32px !important;
    filter: invert(127%); // red color of basket
    -webkit-animation: swayCart 2.5s ease-in-out infinite;
    -moz-animation: swayCart 2.5s ease-in-out infinite;
    -o-animation: swayCart 2.5s ease-in-out infinite;
    animation: swayCart 2.5s ease-in-out infinite;
  }
  .basket-counter {
    color: black;
    font-weight: bold;
  }
  /* Cart animation */
  @-webkit-keyframes swayCart {
    0%   { -webkit-transform: rotate(0deg); }
    3%   { -webkit-transform: rotate(10deg); }
    6%   { -webkit-transform: rotate(0deg); }
    9%   { -webkit-transform: rotate(-10deg); }
    12%   { -webkit-transform: rotate(0deg); }
    15%   { -webkit-transform: rotate(10deg); }
    18%   { -webkit-transform: rotate(0deg); }
    100%   { -webkit-transform: rotate(0deg); }
  }
  @-moz-keyframes swayCart {
    0%   { -moz-transform: rotate(0deg); }
    3%   { -moz-transform: rotate(10deg); }
    6%   { -moz-transform: rotate(0deg); }
    9%   { -moz-transform: rotate(-10deg); }
    12%   { -moz-transform: rotate(0deg); }
    15%   { -moz-transform: rotate(10deg); }
    18%   { -moz-transform: rotate(0deg); }
    100%   { -moz-transform: rotate(0deg); }
  }
  @-o-keyframes swayCart {
    0%   { -o-transform: rotate(0deg); }
    3%   { -o-transform: rotate(10deg); }
    6%   { -o-transform: rotate(0deg); }
    9%   { -o-transform: rotate(-10deg); }
    12%   { -o-transform: rotate(0deg); }
    15%   { -o-transform: rotate(10deg); }
    18%   { -o-transform: rotate(0deg); }
    100%   { -o-transform: rotate(0deg); }
  }
  @keyframes swayCart {
    0%   { transform: rotate(0deg); }
    3%   { transform: rotate(10deg); }
    6%   { transform: rotate(0deg); }
    9%   { transform: rotate(-10deg); }
    12%   { transform: rotate(0deg); }
    15%   { transform: rotate(10deg); }
    18%   { transform: rotate(0deg); }
    100%   { transform: rotate(0deg); }
  }
  /* Cart animation */
}

.vote-input-images-block{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.vote-input-image-selector-outer{
  @include respond-property('width',  45%, 45%, 45%, 100%, 100%, 100%);
}
.vote-item{
  width: 100%;
  @include respond-property('margin-bottom', 50px, 50px, 50px, 30px, 30px, 30px);
  //margin-bottom: 30px;
}

.wares-header{
  @include respond-down(M){
    font-size: 22px;
    font-weight: bold;
    padding: 5px;
  }
  @include respond-up(L){
    font-size: 32px;
    font-weight: bold;
    padding: 20px;
  }
}

.checkout-container2, .products-box{
  @include respond-down(M){
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  @include respond-up(L){
    margin-left: 0;
    margin-right: 0;
    padding-left: 5%;
    padding-right: 5%;
  }
}

.shipping-information, .payment-information{
  @include respond-down(M){
    font-size: 15px;
  }
  @include respond-up(L){
    //font-size: 15px;
  }
}

.radiobox-order{
  padding: 10px;
  border: 2px;
}

.hide-on-mobile {
  @include respond-down(S){
    display: none;
  }
}

.hide-on-desktop {
  @include respond-up(M){
    display: none;
  }
}
