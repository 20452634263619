.collections-box {
  @include respond-property('margin-bottom', 100px, 86px, 70px, 64px, 52px, 40px);

  .blank-collections {
    width: 100%;
    height: 20px;
  }

  .block-header {

    .block-subtitle {
      @include respond-down(S) {
        padding: 0 130px;
      }
      @include respond-down(XS) {
        padding: 0 10%;
      }
    }

    .attributes-container {
      position: relative;

      svg {
        transform: scale(1.35);
        position: absolute;

        @include respond-down(XS) {
          display: none;
        }

        &.kite {
          bottom: 30px;
          left: -5%;
          z-index: 10;
          width: 140px;

          @include respond-down(XL){
            width: 115px;
          }
          @include respond-down(L){
            left: 2%;
            width: 90px;
          }
          @include respond-down(M) {
            display: none;
          }
        }

        &.sole {
          bottom: 45px;
          left: 140px;
          z-index: 10;
          animation: spin 42s linear infinite;

          @include respond-down(XL){
            width: 100px;
            bottom: 26px;
          }
          @include respond-down(XL){
            width: 90px;
            bottom: 20px;
          }
          @include respond-down(M){
            left: -20px;
          }
        }

        &.k-promo {
          bottom: 10px;
          left: 190px;
          z-index: 20;

          @include respond-down(XL){
            width: 70px;
          }
          @include respond-down(L){
            width: 60px;
          }
          @include respond-down(M){
            left: 30px;
          }
        }

        &.k-promo-winter {
          bottom: 10px;
          right: 190px;
          z-index: 10;

          @include respond-down(XL){
            width: 76px;
          }
          @include respond-down(L){
            width: 62px;
          }
          @include respond-down(M){
            right: 30px;
          }
        }

        &.snowflake {
          bottom: 45px;
          right: 140px;
          z-index: 10;
          animation: updown 23s ease infinite;

          @include respond-down(L){
            right: 160px;
            width: 60px;
          }
          @include respond-down(M){
            right: -10px;
          }
        }

        &.snowflake-I {
          bottom: 170px;
          right: 80px;
          animation: updown 17s ease infinite;

          @include respond-down(L){
            bottom: 120px;
            width: 30px;
          }
          @include respond-down(M) {
            display: none;
          }
        }

        &.snowflake-II {
          opacity: 0.63;
          bottom: 90px;
          right: -10px;
          z-index: 10;
          animation: updown 31s ease infinite;

          @include respond-down(L){
            width: 20px;
            bottom: 60px;
          }
          @include respond-down(M) {
            display: none;
          }
        }
      }
    }
  }

  .collections-list-wrapper {
    overflow: hidden;
  }

  .collections-list {
    margin: 0 -28px;

    @include respond-down(XL) {
      margin: 0 -20px;
    }
    @include respond-down(M) {
      margin: 0 -30% 0 -20px;
    }
    @include respond-down(S) {
      margin: 0 -85% 0 -20px;
    }
    @include respond-down(XS) {
      margin: 0 -160% 0 -10px;
    }

    .owl-item {
      @include respond-down(M) {
        opacity: 0;
        transition: opacity 0.35s ease;
      }

      &.active {
        opacity: 1;
      }
    }

    .malebutton {
      font-size: 12px !important;
      background: #58B4E5 !important;
      &:hover {
        background: #2c9ee5 !important;
      }
    }

    .femalebutton {
      font-size: 12px !important;
      background: #EC80A9 !important;
      &:hover {
        background: #ec5187 !important;
      }
    }

    .button {
      z-index: 20;
      position: relative;
      width: 130px;
    }



    .item {
      @include respond-property('height', 614px, 450px, 376px, 430px, 300px, 300px);
      @include respond-property('padding', 37px, 27px, 23px, 27px, 23px, 23px);
      background: #FFF no-repeat bottom right;
      background-size: auto 96%;
      border-radius: 9px;
      box-sizing: border-box;
      margin: 0 28px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      border: 5px solid transparent;

      @include respond-down(XL) {
        padding: 18px;
        margin: 0 20px;
      }

      @include respond(L) {
        background-position: 90px;
      }

      @include respond-down(XS) {
        padding: 14px;
        //margin: 0 16px;
      }

      .height20px {
        height: 20px;
      }

      .width155px {
        width: 130px;
      }

      .hidden {
        display: none;
      }


      .sizes {
        z-index: 20;
        position: relative;
        display: inline-block;
        border-radius: 8px;
        padding: 12px 12px;
        text-align: left;
        color: #FFFFFF;
        font-size: 16px;

        @include respond-down(XL) {
          border-radius: 6px;
          padding: 10px 10px;
        }

        @include respond-down(M) {
          text-align: center;
        }

        @include respond-down(XS) {
          padding: 5px 5px;
        }

        .helper {
          @include respond-property('font-size', 65px, 45px, 35px, 45px, 40px, 40px);
          text-transform: uppercase;
          font-family: "Monly", sans-serif;
          font-weight: bold;
          margin-bottom: 0.2em;
          line-height: 0.9em;
          letter-spacing: 0.03em;
        }

        .size {
          @include respond-property('font-size', 20px, 16px, 14px, 16px, 14px, 14px);
          font-family: "Muller", sans-serif;
          line-height: 1.0em;
          font-weight: 400;
        }
      }


      &.child-1 {
        border-color: #FC6D94;
        background-image: url("/static/images/child-01.jpg");

        .sizes {
          background: #FC6D94;
        }

        .button {
          &:hover {
            //background: #de6d76;
            background: #de4151;
          }
        }
      }

      &.child-2 {
        border-color: #13A449;
        background-image: url("/static/images/child-02.jpg");

        .sizes {
          background: #13A449;
        }

        .button {
          &:hover, &.hover {
            background: #139149;
          }
        }
      }

      &.child-3 {
        border-color: #16E3C8;
        background-image: url("/static/images/child-03.jpg");

        .sizes {
          background: #16E3C8;
        }

        .button {
          &:hover, &.hover {
            background: #16c4a9;
          }
        }
      }
    }

    &.home-collections-items {
      display: flex;

      @include respond-down(M) {
        margin: 0 -20px;
      }
      @include respond-down(S) {
        flex-direction: column;
      }

      .item-wrapper {
        flex: 1 1 100%;

        @include respond-down(S) {
          margin-bottom: 30px;
        }
        @include respond-down(XS) {
          margin-bottom: 12px;
        }

        .item {
          @include respond(M) {
            background-size: auto 76%;
          }
        }
      }
    }
  }
}