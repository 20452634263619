.b-copyright2 {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #23262a;
  z-index: 99999;
  width: 100%;
  cursor: pointer;
}
.copyright2 {
  color: #fff;
  font-family: PTSansRegular, Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
  font-size: 16px;
  hyphens: auto;
  line-height: 24px;
  text-rendering: optimizelegibility;
  padding: 2px 0;
}

.footer-box {
  background: white;
  z-index: 999;
  color: black;
  position: relative;
  left: 0;
  bottom: 0;

  .mobile-only{
    @include respond-up(L){
      display: none !important;
    }
  }
  .desktop-only{
    @include respond-down(M){
      display: none !important;
    }
  }

  .social-part {
    color: white;
    @include respond-property('height', 180px, 160px, 130px, 130px, 130px, 200px);
    @include respond-property('margin-bottom', 96px, 72px, 62px, 62px, 40px, 32px);
    background: #009944;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .container {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      @include respond-down(L){
        flex: 1 1 100%;
      }

      .type-description-space {
        width: 50px;
      }

      .type-description {
        width: 45%;
        color: white;
        text-align: justify;
        font-family: sans-serif;
        @include respond-down(S) {
          display: none;
        }
        @include respond-down(XS) {
          display: none;
        }
      }

      .follow-us {
        .follow-us-style{
          @include respond-property('font-size', 40px, 40px, 25px, 25px, 20px, 20px);
        }
        display: flex;
        font-family: "Monly", sans-serif;

        @include respond-down(XS){
          flex-direction: column;
          align-items: center;
        }

        .text {
          @include respond-property('font-size', 30px, 30px, 25px, 25px, 20px, 20px);
          font-weight: 800;
          margin-right: 50px;

          @include respond-down(S){
            margin-right: 30px;
          }
          @include respond-down(XS){
            margin: 0 0 24px 0;
          }

          .find {
            color: #FFF;
            text-align: left;
            line-height: 1.35em;
            padding-left: 20px;

            font-size: 40px;

            @include respond-down(L) {
              margin-bottom: 8px;
            }
            @include respond-down(XS){
              text-align: center;
              padding: 0;
            }
          }

          .boxed {
            color: #FFF;
            background: #FC6D94;
            height: 46px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            line-height: 1.35em;

            @include respond-down(L) {
              height: 35px;
            }
          }
        }

        .links {
          display: flex;
          align-items: flex-end;

          .social {
            height: 46px;
            margin-right: 32px;

            @include respond-down(XL){
              margin-right: 26px;
            }
            @include respond-down(L){
              height: 35px;
              width: 35px;
              margin-right: 24px;
            }
            @include respond-down(S){
              margin-right: 16px;
            }
            @include respond-down(XS){
              height: 30px;
              width: 30px;
              margin-right: 24px;

              &:last-child {
                margin-right: 0;
              }
            }

            display: inline-block;

            svg {
              @include respond-down(L){
                height: 35px;
              }
              @include respond-down(XS){
                height: 30px;
                width: 30px;
              }

              .figure {
                transition: fill 0.25s ease;
              }
            }

            @include respond-up(L) {
              &:hover {
                svg .figure {
                  fill: #17556A;
                }
              }
            }
          }
        }
      }
    }
  }

  .navigation-part {
    @include respond-property('margin-bottom', 90px, 72px, 62px, 16px, 16px, 16px);

    .container {
      display: flex;

      @include respond-down(M) {
        flex-wrap: wrap;
      }

      .logo-column {
        flex: 1 1 48%;
        margin-right: 80px;
        display: flex;
        align-items: center;

        @include respond-down(L){
          margin-right: 60px;
        }
        @include respond-down(M) {
          flex: 1 1 20%;
          margin-right: 0;
          padding-right: 60px;
        }
        @include respond-down(S){
          flex: 0 0 100%;
          padding-left: 60px;
          margin-bottom: 40px;
        }
        @include respond-down(XS){
          padding: 0;
          flex-direction: column;
        }

        .kakadu-logo-svg {
          @include respond-property('width', 124px, 104px, 86px, 86px, 73px, 60px);
          @include respond-property('margin-right', 60px, 40px, 28px, 28px, 26px, 0);

          @include respond-down(XS){
            margin-bottom: 20px;
          }
        }

        .text {
          margin-bottom: 18px;

          @include respond-down(M){
            margin-bottom: 12px;
          }
          @include respond-down(XS){
            margin-bottom: 0;
          }

          .text {
            @include respond-property('font-size', 18px, 14px, 12px, 12px, 12px, 12px);
            color: #272727;
            line-height: 1.45em;

            @include respond-down(XS){
              text-align: center;
              padding: 0 15%  ;
            }
          }
        }
      }
      
      .links-column {}

      .copyright-small {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: #17C256;
        display: flex;
        margin-top: 25px;
        order: 10;

        @include respond-down(M){
          position: absolute;
          margin-top: 1px;
          left: 0;
        }

        @include respond-down(XS){
          flex: 0 0 100%;
          align-items: center;
          justify-content: space-around;
          position: initial;
          order: 1;
          padding: 10px 0 18px 0;
        }

        @include respond-up(XL) {
          display: none;
        }
      }

      .maker-column {
        text-align: right;
        position: relative;

        @include respond-down(M) {
          flex: 0 0 100%;
          display: flex;
          align-items: center;
          padding-top: 50px;
          justify-content: flex-end;
        }

        @include respond-down(XS){
          flex: 0 0 100%;
          justify-content: space-between;
          padding-top: 18px;
          flex-wrap: wrap;
        }

        .text {
          color: #909090;
          white-space: nowrap;
          text-align: right;
          margin-bottom: 1em;
          line-height: 1.35em;
          font-size: 14px;
          order: 5;

          @include respond-down(L){
            font-size: 12px;
          }
          @include respond-down(M) {
            margin-bottom: 0;
            margin-right: 15px;
            br {
              display: none;
            }
          }
          @include respond-down(XS){
            font-size: 10px;
            text-align: left;
          }
        }

        a {
          order: 5;
        }

        svg {
          @include respond-property('width', 168px, 160px, 130px, 80px, 80px, 80px);

          path {
            @include respond-down(M){
              fill: #909090
            }
          }
        }
      }
    }
  }




  .footer2 {
    padding-top: 25px;
    padding-bottom: 0;
    text-align: right;
    background-color: #32373d !important;
    font-family: PTSansRegular, Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #eaeaea;
    text-decoration: none;
    //margin-top: 25px;
    //margin-bottom: 25px;
    //text-transform: uppercase;
    .pb-4-mobile {
      padding-bottom: 30px;
    }
  }

  .footer2 a:hover, .footer2 a:focus {
    color: #12BF8C !important;
  }
  .footer2 a{
    font-family: PTSansRegular, Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #eaeaea;
    text-decoration: none;
    text-transform: none;
  }

  .likeh5 {
    font-family: PTSansRegular, Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #eaeaea;
    text-decoration: none;
    margin-top: 25px;
    margin-bottom: 25px;
    text-transform: uppercase;
  }
  .mg25{
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .crossway-text {
    color: #eaeaea;
    font-size: 10px;
    margin: 0 0 5px;
    text-transform: uppercase;
    text-align: center;
  }
  .crossway22 {
    width: 180px;
    height: auto;
    margin-bottom: 25px;
  }
  .text-xs-left {
    text-align: left!important;
  }
  .footer-part {
    @include respond-property('height', 116px, 94px, 78px, auto, auto, auto);
    background: #F9F9F9;
    display: flex;
    align-items: center;

    @include respond-down(S) {
      padding: 28px 0 22px 0;
    }

    .container {
      display: flex;
      align-items: center;
      color: #909090;
      font-size: 14px;
      line-height: 1.45em;

      @include respond-down(XL) {
        font-size: 12px;
      }

      @include respond-down(XS) {
        font-size: 10px;
      }

      .copyright {
        white-space: nowrap;
        color: #17C256;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 18px;
        padding-right: 40px;

        @include respond-down(XL) {
          font-size: 16px;
        }

        @include respond-down(L) {
          display: none;
        }
      }

      .right-part {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: -32px;

        @include respond-down(XL) {
          margin-right: -24px;
        }
        @include respond-down(L) {
          margin-left: -24px;
          flex: 1 1 100%;
        }
        @include respond-down(M) {
          flex-wrap: wrap;
          margin: 0 0 18px 0;
          align-items: flex-start;
        }
        @include respond-down(S){
          flex-wrap: wrap;
        }

        .part-item {
          color: #909090;
          padding: 0 32px;

          @include respond-down(XL) {
            padding: 0 24px;
          }
          @include respond-down(M) {
            padding: 0 0;
          }

          &.strong {
            white-space: nowrap;
            font-weight: 700;
          }

          .public-info {
            color: #909090;
            font-weight: 800;
            text-decoration: underline;
          }
        }

        .public-info-wrapper {
          border-right: 1px solid rgba(178, 178, 178, 0.38);
          height: 68px;
          display: flex;
          align-items: center;

          @include respond-down(XL){
            height: 50px;
          }
          @include respond-down(M) {
            border-bottom: 1px solid rgba(178, 178, 178, 0.38);
            border-right: none;
            height: auto;
            flex: 0 0 100%;
            padding-bottom: 18px;
            padding-top: 18px;
            margin-bottom: 18px;
          }
        }

        .details-title {
          @include respond-down(M){
            flex: 1 1 100%;
            margin-bottom: 10px;
            font-size: 12px;
          }
        }

        .details-company {
          @include respond-down(L){
            flex: 1 1 40%
          }
          @include respond-down(M) {
            flex: 0 0 40%
          }

          @include respond-down(S){
            flex: 0 0 50%;
            margin: 0;
          }
          @include respond(XS){
            flex: 0 0 100%;
            padding-right: 30%;
            margin-bottom: 12px;
          }
        }

        .details-data {
          flex: 0 0 auto;

          @include respond(S){
            flex: 0 0 50%;
            margin: 0;
            padding-left: 20px;
          }

          .mailto {
            text-decoration: underline;
            font-weight: 700;
            color: #909090;
          }
        }
        .hidden{
          display: none !important;
        }

      }
    }
  }
}