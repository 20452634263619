.button {
  @include respond-property('min-width', 250px, 220px, 180px, 220px, 220px, 220px);
  @include respond-property('height',    56px,   50px,  40px,  50px,  50px,  50px);
  @include respond-property('font-size', 20px,   18px,  16px,  18px,  18px,  18px);
  display: inline-flex;
  border-radius: 8px;
  align-items: center;
  justify-content: space-around;
  font-weight: 700;
  background: #FC6D94;
  color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.25s ease;
  user-select: none;
  padding-top: 0.1em;
  padding-left: 1em;
  padding-right: 1em;

  @include respond-down(L){
    border-radius: 6px;
  }

  &.button-yellow {
    background: #FFEF60;
    color: #373737;
  }
  &.button-green {
    background: #13A449;
  }
  &.button-zakaz {
    background: #F89536;
  }
  &.button-green-x {
    background: #16E3C8;
  }
  &.button-red {
    background: #FF5D5D;
  }

  &.button-small {
    border-radius: 4px;
    min-width: unset;
    @include respond-property('height',        40px,   35px,  30px,  30px,  30px,  26px);
    @include respond-property('font-size',     18px,   16px,  14px,  14px,  14px,  14px);
    @include respond-property('padding-left',  17px,   13px,  11px,  11px,  11px,  9px);
    @include respond-property('padding-right', 17px,   13px,  11px,  11px,  11px,  9px);

    svg {
      margin-left: 0.5em;
      height: 0.8em;
      transition: transform 0.35s ease;

      path {
        stroke: #fff;
      }
    }
  }

  @include respond-up(L){
    &:hover, &.hover {
      background: #FF5D5D;

      &.button-yellow {
        background: #FFEF60;
      }
      &.button-green {
        background: #62E994;
      }
      &.button-green-x {
        background: #6FECDB;
      }
      &.button-red {
        background: #FF5D5D;
      }
    }

    &.button-small:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
}
