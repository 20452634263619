.popular-box {
  overflow: hidden;

  .header {
    min-width: 95%;
    @include respond-property('margin-bottom', 70px, 60px, 50px, 30px, 50px, 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .to-catalog {
      background: #6FECDB;

      @include respond-up(L){
        &:hover {
          background: #16E3C8;
        }
      }

      @include respond-down(XS) {
        display: none;
      }
    }
  }

  .popular-items {
    // @include respond-property('margin', 0 -30px, 0 63px, 0 -15px, 0 -12px, 0 -12px, 0 -6px);
    // @include respond-property('margin-bottom', 74px, 62px, 150px, 120px, 100px, 20px);
    position: relative;
    width: 100vw;

    .arrow-button {
      z-index: 50;
      position: absolute;
      bottom: 50%;

      @include respond-down(L){
        bottom: -78px;
      }
      @include respond-down(S){
        bottom: -58px;
      }
      @include respond-down(XS){
        display: none;
      }

      &.to-right {
        right: -70px;

        @include respond-down(XL){
          right: -63px;
        }
        @include respond-down(L){
          right: 50%;
          margin-right: -62px;
        }
      }

      &.to-left {
        left: -70px;

        @include respond-down(XL){
          left: -63px;
        }
        @include respond-down(L){
          left: 50%;
          margin-left: -62px;
        }
      }

    }

    .items-carousel {
      @include respond-property('margin-left', 6%, 6%, -7%, 6%, 5%, -80px);

      .item-wrapper {
        @include respond-property('margin', 0 30px, 0 10px, 0 15px, 0 12px, 0 12px, 0 6px);
        min-width: 200px;
      }

      .gender {
        @include respond-property('top', -73px, -73px, -73px, -60px, -60px, -60px);
      }

      .owl-item {
        opacity: 0;
        transition: opacity 0.35s ease;

        &.active {
          opacity: 1;
        }

        .product-cart {
          @include respond-property('margin-right', 32px, 32px, 32px, 32px, 32px, 32px);
        }
      }

      //.owl-item {
      //  opacity: 0;
      //  transition: opacity 0.35s ease;
      //
      //  &.active {
      //    opacity: 1;
      //  }
      //}
    }
  }

  .to-catalog {
    display: flex;
    justify-content: space-around;
    margin-bottom: 42px;

    @include respond-up(S){
      display: none;
    }
  }

  .popular-banner {
    @include respond-property('height', 446px, 370px, 310px, 310px, 260px, 196px);
    @include respond-property('margin-bottom', 110px, 80px, 64px, 60px, 60px, 35px);
    @include respond-property('padding', 90px 70px, 80px 50px, 68px 46px, 56px 38px, 42px 30px, 20px);
    border-radius: 9px;
    background: #fafafa no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .title {
      @include respond-property('font-size', 25px, 20px, 18px, 18px, 18px, 14px);
      margin-bottom: 1.5em;
      color: #17556A;
      text-transform: uppercase;
      position: relative;
      letter-spacing: 0.104em;
      font-weight: 700;
      line-height: 1.35em;
      margin-top: 1em;

      .text {
        position: relative;
        z-index: 10;
        padding-left: 18px;
      }

      &:after {
        position: absolute;
        z-index: 5;
        width: 270px;
        top: -14px;
        bottom: -12px;
        left: 0;
        content: '';
        background: #FFEF60;

        @include respond-down(XS) {
          width: 160px;
        }
      }
    }

    .sub-title {
      @include respond-property('font-size', 45px, 40px, 35px, 35px, 30px, 20px);
      color: #FFFFFF;
    }

    .button {
      @include respond-down(XS) {
        height: 25px;
        font-size: 14px;
        min-width: 0;
        padding: 0 18px;
      }
    }
  }
}
