.katalog_link {
  left: 10px;
  width: 110px;
  height: 30px;
  top:-2px;
  position:relative;
  background-image: url(/media/icons/katalog/katalog.svg);
  background-repeat: no-repeat;
  &:hover {
    background-image: url(/media/icons/katalog/katalog_green.svg);
  }
}

.katalog_link_active {
  left: 10px;
  width: 110px;
  height: 30px;
  top:-2px;
  position:relative;
  background-image: url(/media/icons/katalog/katalog_green.svg);
  background-repeat: no-repeat;
  &:hover {
    background-image: url(/media/icons/katalog/katalog_green.svg);
  }
}

.mm-opener-wrapper {
  position: absolute;
  right: 5%;
  top: 20px;
  margin-right: -10px;

  @include respond-up(L){
    display: none;
  }

  .mm-search-init {
    display: none;
    position: absolute;
    right: 5%;
    margin-right: 50px;
    border: 0;
    background: none;
    padding: 8px;

    @include respond-down(S){
      padding: 9px 4px;
      margin-right: 40px;
    }
    @include respond-down(XS){
      padding: 7px 4px;
      margin-right: 36px;
    }

    svg {
      @include respond-down(S){
        width: 24px;
        height: 24px;
      }
      @include respond-down(XS){
        width: 20px;
        height: 20px;
      }
    }
  }

  .mm-trigger {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 40;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .mm-opener {
    position: relative;
    width: 47px;
    height: 43px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 30;
    touch-action: none;

    @include respond-down(S){
      width: 43px;
      height: 40px;
    }

    @include respond-down(XS){
      width: 40px;
      height: 37px;
    }

    .line {
      border-radius: 3px;
      background: #17C256;
      width: 100%;
      height: 5px;
      transition: transform 0.35s ease;

      @include respond-down(S){
        height: 4px;
      }
      @include respond-down(XS){
        height: 3px;
      }
    }
  }
}

.flex-vcenter {
  display: flex;
  align-items: center;
}

.mm-catalog {
  display: block;
  left: 5%;
  top: 25px;
  position: absolute;
  z-index: 20;

  @include respond-up(L){
    display: none;
  }

}

.mm-left-social {
  position: absolute;
  display: block;
  transform: rotate(20deg);
  left: -350px;
  top: 40px;
  @include respond-up(XL){
    left: -350px;
    top: 40px;
  }
  z-index: 20;
  @include respond-down(M){
    display: none;
  }
}

.mm-social {
  display: block;
  left: 66%;
  top: 25px;
  position: absolute;
  z-index: 20;

  @include respond-up(L){
    display: none;
  }

  .links {
    display: flex;
    margin-left: -5px;

    .social {
      text-decoration: none;
      display: inline-block;
      margin-right: 10px;
      width: 32px;

      padding-right: 0.5rem;
      @include respond-up(L){
        padding-right: 1rem;
      }

      @include respond-down(S){
        margin-right: 10px;
        width: 26px;
      }

      svg {
        margin: 5px;
        width: 22px;
        height: 22px;

        .fly {
          fill: #FFFFFF;
        }

        @include respond-down(S){
          width: 20px;
          height: 20px;
        }

        @include respond-down(XS){
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.menu-opened {
  .mm-opener {
    .line:nth-child(1){
      transform: rotate(45deg) translate(0, 10px) scale(1.2, 1);

      @include respond(M){
        transform: rotate(45deg) translate(0, 13px) scale(1.4, 1);
      }
      @include respond(S){
        transform: rotate(45deg) translate(0, 12px) scale(1.3, 1);
      }
    }

    .line:nth-child(2){
      transform: scale(0,1) translateX(-40px);
    }

    .line:nth-child(3){
      transform: rotate(-45deg) translate(0, -10px) scale(1.2, 1);

      @include respond(M){
        transform: rotate(-45deg) translate(0, -13px) scale(1.4, 1);
      }
      @include respond(S){
        transform: rotate(-45deg) translate(0, -12px) scale(1.3, 1);
      }
    }
  }
}
