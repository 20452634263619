.sale-line-box {
  @include respond-property('height', 50px, 42px, 38px, 38px, 66px, 66px);
  @include respond-property('margin-bottom', 44px, 44px, 25px, 18px, 13px, 13px);
  background: #FBDEED;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @include respond-down(S){
    flex-direction: column;
  }

  .group {
    position: relative;
    padding-left: 80px;

    @include respond-down(XL){
      padding-left: 60px;
    }
    @include respond-down(M){
      padding-left: 50px;
    }
    @include respond-down(S){
      margin-bottom: 8px;
    }

    svg {
      position: absolute;
      left: 0;
      top: -32px;

      @include respond-down(XL){
        width: 60px;
        top: -32px;
      }
      @include respond-down(M){
        width: 50px;
        top: -30px;
      }
    }
  }

  .text {
    @include respond-property('font-size', 16px, 16px, 14px, 14px, 12px, 12px);
    @include respond-property('margin-left', 10px, 10px, 7px, 7px, 4px, 4px);
    @include respond-property('margin-right', 44px, 20px, 20px, 16px);
    font-weight: 600;
    color: #FD6F71;
    letter-spacing: 0.018em;
  }

  .button {
    @include respond-property('height', 30px, 24px, 24px, 24px, 21px, 21px);
    font-size: 14px;
    background: #3CE5CE;

    @include respond-down(S){
      font-size: 12px;
    }

    &:hover {
      background: #16E3C8;
    }
  }
}