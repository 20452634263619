//@import "_programs-box-catalog";

.default-menu-box {
  background: #FFFFFF;

  .container-full {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond-property('padding-top',    15px, 15px, 24px, 24px, 24px, 19px);
    @include respond-property('padding-bottom', 38px, 28px, 18px, 28px, 24px, 24px);

    @include respond-down(M) {
      justify-content: space-around;
    }

    .logo-wrapper {
      display: flex;
      align-items: center;

      .logo {
        @include respond-property('width', 94px, 68px, 56px, 64px, 57px, 42px);
      }

      .lead {
        @include respond-property('margin-left', 35px, 25px, 20px);
        @include respond-property('margin-top', 18px, 14px, 12px);
        line-height: 1.35em;

        @include respond-down(M) {
          display: none;
        }
        
        svg {
          @include respond-property('width', 294px, 236px, 170px);
        }
      }
    }
    .hidden {
      display: none !important;
    }
    .round {
      border-radius: 5px; /* Радиус скругления */
      border: 0px solid black; /* Параметры рамки */
    }
    .top-banners-row{
      @include respond-down(M) {
        display: none;
      }
      @include respond-up(L) {
      }
    }
    .top-banner {
      display: flex;
      align-items: center;
      justify-content: center;
      //position: absolute;
      ////left: 500px;
      //top: 90px;
      //@include respond-property('display', block, block, block, none, none, none);
      //@include respond-property('left', 500px, 500px, 350px);
      img {
        //@include respond-property('width', 800px, 600px, 400px);
        //@include respond-property('height', 75px, 75px, 75px);
        @include respond-property('border-radius', 5px, 5px, 5px);
        @include respond-down(L) {
          //min-height: 70px;
        }
        @include respond-up(XL) {
          //min-height: 92px;
        }
        max-height: 95px;
      }
    }
    .programs-box2 {
      img {
        border-radius: 5px;
        max-height: 95px;
        @include respond-down(L) {
          //min-height: 70px;
        }
        @include respond-up(XL) {
          //min-height: 92px;
        }
      }
    }
    .programs-container {
      position: absolute;
      @include respond-property('left', 1150px, 1150px, 760px);
      top: 80px;
    }

    .mt-20px{
      margin-top: 20px;
    }

    .menu-items-wrapper {
      //display: flex;
      //align-items: center;
      //margin-top: -40px;
      //position: absolute;
      width: 100%;

      //top: 30px;
      //right: 10px;
      //left: 260px;
      .w190 {
        width: 190px;
      }

      @include respond-down(M) {
        display: none;
      }

      .right_top_menu_big {
        @include respond-down(L) {
          //display: none !important;
        }
      }
      .right_top_menu_small {
        display: none !important;
        //@include respond-up(XL) {
        //  display: none !important;
        //}
      }

      .menu-items {
        //padding-top: 10px;
        vertical-align: middle;
        .item {
          //@include respond-property('font-size', 20px, 18px, 16px);
          //@include respond-property('font-size', 1vw, 2vw, 3vw);
          font-size: 16px;
          color: #565555;
          margin-left: 50px;
          @include respond-down(L) {
            font-size: 16px;
            margin-left: 20px;
          }
          //font-size: 1vw;
          //@include respond-property('margin', 0 20px, 18px, 16px);
          color: #17556A;
          font-weight: 700;
          padding: 5px;
          transition: color 0.35s ease;

          &:hover, &.current {
            color: #3CE5CE
          }
        }
      }

      .search-wrapper {
        min-width: 350px;
        margin-top: -8px;
        display: flex;
        align-items: center;

        .search-label {
          font-weight: bold;
          font-size: 16px;
          color: #464646;
          letter-spacing: 0.018em;
          padding-right: 18px;

          @include respond-down(XL){
            font-size: 14px;
          }
        }

        .input-wrapper {
          position: relative;
          //width: 650px;
          ::-webkit-input-placeholder {color:rgb(235,235,235);}
          ::-moz-placeholder          {color:rgb(235,235,235);}/* Firefox 19+ */
          :-moz-placeholder           {color:rgb(235,235,235);}/* Firefox 18- */
          :-ms-input-placeholder      {color:rgb(235,235,235);}

          @include respond-down(XL){
            //width: 470px;
          }
          @include respond-down(L){
            //width: 340px;
          }
          @include respond-down(M){
            width: auto;
          }

          .input {
            width: 100%;
            border-radius: 46px;
            border: 2px solid #EBEBEB;
            height: 46px;
            line-height: 44px;
            font-size: 16px;
            padding: 0 54px 0 23px;
            position: relative;
            z-index: 20;
            transition: border 0.25s ease;

            @include respond-down(XL){
              height: 36px;
              line-height: 34px;
              padding: 0 40px 0 16px;
            }
            @include respond-down(M){
              border-color: #FFF;
              height: 40px;
              line-height: 38px;
            }
            @include respond-down(XS){
              height: 36px;
              line-height: 34px;
            }

            @include respond-up(L){
              &:hover, &:focus {
                border-color: #FBDEED;
              }
              &:hover + .input-button, &:focus + .input-button {
                background: #FBDEED;

                svg path {
                  fill: #FFF;
                }
              }
            }
          }

          .input-button {
            cursor: pointer;
            width: 46px;
            height: 46px;
            border-radius: 46px;
            position: absolute;
            z-index: 30;
            right: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: #EBEBEB;
            border: none;
            transition: background 0.25s ease;

            @include respond-down(XL){
              width: 36px;
              height: 36px;
            }
            @include respond-down(M){
              width: 40px;
              height: 40px;
              background: #FFF;
            }
            @include respond-down(XS){
              width: 36px;
              height: 36px;
            }

            svg {
              width: 80%;
              margin-top: 0;

              path {
                transition: fill 0.25s ease;
                @include respond-down(M){
                  fill: #FBDEED;
                }
              }
            }

            &:hover {
              svg path {
                fill: #FF5D5D;
              }
            }
          }
        }

        &.search-wrapper-mobile {
          max-height: 0;
          transition: max-height 0.35s ease;
          overflow: hidden;

          @include respond-up(L){
            display: none;
          }
          @include respond-down(M){
            display: block;
          }

          .input-wrapper {
            background: #FBDEED;
            padding: 12px 5%;
            margin-bottom: 5px;

            .input-button {
              right: 5%;
              top: 12px;
            }
          }
        }
      }


      svg {
        //margin-left: 20px;
        margin-top: -25px;
        @include respond-property('width', 76px, 68px, 46px);
        transition: transform 0.45s ease;

        &:hover {
          transform: rotate(45deg);
        }
      }
    }
  }
  
}

.mobile-menu-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 126px;
  background: #FFF;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  z-index: 270;
  transition: max-height 0.85s linear, opacity 0.45s linear;
  box-shadow: 0px 10px 7px rgba(0,0,0,0.1);
  
  @include respond-up(L) {
    display: none;
  }

  @include respond-down(S) {
    top: 120px;
  }

  @include respond-down(XS) {
    top: 100px;
    flex-wrap: wrap;
  }

  .links-column {

    .title {
      cursor: pointer;
      font-size: 18px;
    }
  
    &.opened {
      .link-items {
        max-height: 400px;
      }
    }
  }

  .bottom-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px 0 14px 0;
    font-size: 12px;
    color: #17C256;

    @include respond-down(XS) {
      flex-wrap: wrap;
    }
    
    strong {
      color: #17C256;
    }
    
    .by-crossway {
      opacity: 0.6;
      color: #909090;
      font-size: 10px;
      display: flex;
      align-items: center;

      @include respond-down(XS) {
        justify-content: space-between;
        width: 100%;
      }

      svg {
        width: 80px;
        margin-left: 7px;
        
        path {
          fill: #909090;
        }
      }
    }
  }
}

.menu-opened {
  .mobile-menu-content {
    max-height: 1300px;
    opacity: 1;
  }
}

